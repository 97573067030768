
/* ATTENTION!
This file is automatically generated by scripts/set-env.ts
Please do not change or commit it
*/
import { IEnvironment } from '@skychute/ui-models';
function isEqual(a: any, b: any): boolean {
    return a === b;
}
export const config: IEnvironment = {
    production: isEqual('true', 'true'),
    services: {
        data: {
          endpoint: 'https://demo-hasura.projectre.com.au',
          path: '/v1/graphql',
          logQueries: 'false'
        },
        aws: {
          UserPoolId: 'ap-southeast-2_Xjj0i0S7S',
          AppClientId: '3as2jbsse40ibged5t0624lpus',
          cloudUri: 'https://djpeosbyg3.execute-api.ap-southeast-2.amazonaws.com/dev',
        },
        analytics: {
            segment: {
                enabled: isEqual('false', 'true'),
                writeKey: 'CKazVWLN9swSOQAxq5aOOuUzu9MFuARF',
            },
        },
    },
    application: {
        version: '1.92.0',
        gitHead: '49f4344ee4a4616785102d08200051f3f2e8f146',
        branch: 'demo',
        buildDate: 'September 17th 2024, 6:31:37 pm',
        buildNumber: '856',
    },
    stripe: {
        publicKey: 'pk_test_51JVQJzICXqe0hIJdVHDRyFoCVJbQwhWPayB7eHg1boYOy7FsCv7Sj1CJSYA2u6vPj4zRNbmsR6tsVu48e0yQEhYT00ycLtgphf'
    },
    addressFinder: {
        key: 'RBHCYUV987D4XKNPT6FL',
        src: 'https://api.addressfinder.io/assets/v3/widget.js'
    },
    courier_push_client_key:'ODM2OTc0NWItODA5NS00OTI3LWE1ZTEtMWI0OGEyZjBkNjg0L3Rlc3Q=',
    novu_push_client_key:'GVO-2E8_6qNx',
};
